<template>
  <div>
    <b-card
      no-body
      class="liveinfo-form"
    >
      <div class="m-2">
        <b-row>
          <b-col
            cols="12"
            md="10"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <v-date-picker
              v-model="query.degreeMonth"
              type="month"
              value-type="YYYYMM"
              placeholder="차수 기준 월"
              class="el-def"
              style="width:150px"
            />
            <v-select
              v-model="query.degree"
              :options="codes.degree"
              placeholder="차수 선택"
              :reduce="option => option.code"
              class="el-def"
              style="width:120px"
            />
            <b-form-datepicker
              v-model="query.joinDateFr"
              class="el-def"
              placeholder="참여가능기간(Fr)"
            />
            <label class="ml-25 mr-50">~</label>
            <b-form-datepicker
              v-model="query.joinDateTo"
              class="el-def"
              placeholder="참여가능기간(To)"
            />
            <b-form-input
              v-model="query.filterStr"
              class="el-def"
              placeholder="제목 or 키워드를 검색하세요"
              @keyup.enter="refetchData()"
            />
          </b-col>
          <!-- Search -->
          <b-col
            cols="12"
            md="2"
            class="d-flex align-items-center justify-content-end"
          >
            <b-button
              variant="primary"
              class="btn-search"
              @click.prevent="refetchData()"
            >
              조회
            </b-button>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <b-card
      no-body
    >
      <b-table
        ref="refPollTable"
        primary-key="id"
        :items="fetchPolls"
        :fields="tableColumns"
        :sort-by.sync="sortBy"
        :sort-desc.sync="isSortDesc"
        responsive
        sticky-header
        selectable
        select-mode="single"
        class="position-relative"
        @row-selected="onRowSelectedPoll"
      >
        <template #cell(degree)="data">
          <span class="text-nowrap">
            {{ data.item.degreeMonth }}-{{ data.value }}
          </span>
        </template>
        <template #cell(startDate)="data">
          <span class="text-nowrap">
            {{ data.value | $dateFormatter('YYYY-MM-DD') }}
            ~
            {{ data.item.endDate | $dateFormatter('YYYY-MM-DD') }}
          </span>
        </template>
        <template #cell(createDate)="data">
          <span class="text-nowrap">
            {{ data.value | $dateFormatter('YYYY-MM-DD') }}
          </span>
        </template>
      </b-table>
    </b-card>

    <b-card
      class="mt-2"
    >
      <b-row>
        <b-col
          cols="12"
          md="6"
          class="d-flex align-items-center justify-content-start"
        >
          <feather-icon
            icon="EditIcon"
            size="19"
          />
          <h4 class="mb-0 ml-50">
            상세
          </h4>
        </b-col>
        <b-col
          cols="12"
          md="6"
          class="d-flex align-items-center justify-content-end"
        >
          <b-button
            variant="outline-primary"
            @click.prevent="resetPollData()"
          >
            <feather-icon
              icon="PlusSquareIcon"
              class="mr-50"
            />
            <span>신규</span>
          </b-button>
          <b-button
            variant="primary"
            class="ml-1"
            @click.prevent="savePoll()"
          >
            <feather-icon
              icon="SaveIcon"
              class="mr-50"
            />
            <span>저장</span>
          </b-button>
          <b-button
            variant="outline-secondary"
            class="ml-1"
            @click.prevent="removePoll()"
          >
            <feather-icon
              icon="Trash2Icon"
              class="mr-50"
            />
            <span>삭제</span>
          </b-button>
        </b-col>
      </b-row>
      <validation-observer ref="formRules">
        <b-row class="mt-1">
          <b-col
            cols="12"
            md="10"
          >
            <b-form-group
              label="제목"
              label-for="poll-title"
            >
              <validation-provider
                #default="{ errors }"
                name="제목"
                rules="required"
              >
                <b-form-input
                  id="poll-title"
                  v-model="pollData.pollTitle"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            cols="12"
            md="3"
          >
            <b-form-group
              label="참여가능기간(Fr)"
              label-for="join-date-fr"
            >
              <validation-provider
                #default="{ errors }"
                name="참여가능기간(Fr)"
                :rules="{ required: true, before: pollData.endDate }"
              >
                <b-form-datepicker
                  id="join-date-fr"
                  v-model="pollData.startDate"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="3"
          >
            <b-form-group
              label="참여가능기간(To)"
              label-for="join-date-to"
            >
              <validation-provider
                #default="{ errors }"
                name="참여가능기간(To)"
                :rules="{ required: true, after: pollData.startDate }"
              >
                <b-form-datepicker
                  id="join-date-to"
                  v-model="pollData.endDate"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            cols="12"
            md="3"
          >
            <b-form-group
              label="기준 월"
              label-for="degree-month"
            >
              <validation-provider
                #default="{ errors }"
                name="기준 월"
                rules="required"
              >
                <v-date-picker
                  id="degree-month"
                  v-model="pollData.degreeMonth"
                  type="month"
                  value-type="YYYYMM"
                  placeholder="기준 월"
                  style="width:100%"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="3"
          >
            <b-form-group
              label="차수 선택"
              label-for="poll-degree"
            >
              <validation-provider
                #default="{ errors }"
                name="차수 선택"
                rules="required"
              >
                <v-select
                  id="poll-degree"
                  v-model="pollData.degree"
                  :options="codes.degree"
                  placeholder="차수 선택"
                  :reduce="option => option.code"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            cols="12"
            md="10"
          >
            <b-form-group
              label="키워드"
              label-for="poll-keyword"
            >
              <validation-provider
                #default="{ errors }"
                name="키워드"
                rules="required"
              >
                <b-form-input
                  id="poll-keyword"
                  v-model="pollData.keyword"
                  :state="errors.length > 0 ? false : null"
                  placeholder="키워드를 ','로 구분하여 입력하세요."
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
      </validation-observer>
    </b-card>
    <b-card
      class="mt-2"
    >
      <b-row>
        <b-col
          cols="12"
          md="6"
          class="d-flex align-items-center justify-content-start"
        >
          <feather-icon
            icon="EditIcon"
            size="19"
          />
          <h4 class="mb-0 ml-50">
            문항 관리
          </h4>
        </b-col>
        <b-col
          cols="12"
          md="6"
          class="d-flex align-items-center justify-content-end"
        >
          <b-button
            variant="outline-primary"
            @click.prevent="addElement()"
          >
            <feather-icon
              icon="PlusSquareIcon"
              class="mr-50"
            />
            <span>문항 추가</span>
          </b-button>
          <b-button
            variant="primary"
            class="ml-1"
            @click.prevent="savePollElements()"
          >
            <feather-icon
              icon="SaveIcon"
              class="mr-50"
            />
            <span>저장</span>
          </b-button>
        </b-col>
      </b-row>
      <validation-observer ref="elementRules">
        <div class="mt-1">
          <div
            v-for="(item, index) in pollElements"
            :id="`element${item.id}`"
            :key="item.id"
            ref="element"
          >
            <b-row>
              <b-col
                cols="12"
                md="10"
              >
                <b-form-group
                  :label="`문항${(index + 1)} 제목`"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="문항 제목"
                    rules="required"
                  >
                    <b-form-input
                      v-model="item.title"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row
              v-for="(detail, dIdx) in item.pollElementDetails"
              :id="`detail${detail.id}`"
              :key="detail.id"
              ref="detailElement"
              style="margin-top: 5px"
            >
              <b-col
                cols="12"
                md="1"
                class="d-flex align-items-center justify-content-center"
              >
                <b-badge variant="light-secondary">
                  보기{{ (dIdx + 1) }}
                </b-badge>
              </b-col>
              <b-col
                cols="12"
                md="8"
              >
                <validation-provider
                  #default="{ errors }"
                  name="보기"
                  rules="required"
                >
                  <b-form-input
                    v-model="detail.subText"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>
              <b-col
                cols="12"
                md="2"
                class="d-flex align-items-center justify-content-start"
              >
                <b-button
                  variant="outline-danger"
                  size="sm"
                  @click="removeElementDetail(item.pollElementDetails, dIdx)"
                >
                  <feather-icon
                    icon="XIcon"
                    class="mr-25"
                  />
                  <span>삭제</span>
                </b-button>
              </b-col>
            </b-row>
            <b-row class="mt-1">
              <b-col
                cols="12"
                md="10"
                class="d-flex align-items-center justify-content-center"
              >
                <b-button
                  variant="primary"
                  @click.prevent="addElementDetail(item)"
                >
                  <feather-icon
                    icon="PlusIcon"
                    class="mr-50"
                  />
                  <span>보기 추가</span>
                </b-button>
                <b-button
                  variant="outline-secondary"
                  class="ml-1"
                  @click.prevent="removeElement(item)"
                >
                  <feather-icon
                    icon="MinusIcon"
                    class="mr-50"
                  />
                  <span>문항 삭제</span>
                </b-button>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12">
                <hr>
              </b-col>
            </b-row>
          </div>
        </div>
      </validation-observer>
    </b-card>
  </div>
</template>

<script>
import axios from '@axios'
import dayjs from 'dayjs'
import vSelect from 'vue-select'
import { BBadge } from 'bootstrap-vue'
import { ref, getCurrentInstance } from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { errorFormatter } from '@core/utils/filter'

export default {
  components: {
    vSelect,
    BBadge,

    ValidationProvider,
    ValidationObserver,
  },

  setup() {
    const toast = useToast()
    const instance = getCurrentInstance()
    const bvModal = instance.proxy.$bvModal
    const refs = instance.proxy.$refs

    const pollInitState = {
      id: null,
      pollTitle: null,
      startDate: null,
      endDate: null,
      degreeMonth: null,
      degree: null,
      keyword: null,
    }
    const pollData = ref({ ...pollInitState })

    const resetPollData = () => {
      Object.assign(pollData.value, pollInitState)
      refs.formRules.reset()

      pollElements.value = []
    }

    const codes = ref({
      degree: [
        { label: '1차', code: 1 },
        { label: '2차', code: 2 },
        { label: '3차', code: 3 },
        { label: '4차', code: 4 },
        { label: '5차', code: 5 },
      ],
    })

    // Query Data
    const query = ref({
      degreeMonth: dayjs().format('YYYYMM'),
      degree: null,
      joinDateFr: null,
      joinDateTo: null,
      filterStr: null,
    })
    // Query Data End.

    // Main Table Config
    const refPollTable = ref(null)
    const sortBy = ref('degree')
    const isSortDesc = ref(true)
    const totalRecords = ref(0)
    const tableColumns = [
      { key: 'id', label: 'Id', sortable: true },
      { key: 'degree', label: '차수', sortable: true },
      { key: 'startDate', label: '참여가능기간', sortable: true },
      { key: 'pollTitle', label: '제목', sortable: true },
      { key: 'keyword', label: '키워드', sortable: true },
      { key: 'createDate', label: '등록일', sortable: true },
    ]
    const refetchData = () => {
      refPollTable.value.refresh()
    }
    const onRowSelectedPoll = items => {
      const item = items[0]
      if (item && item.id) {
        fetchPoll(item.id)
        fetchPollElements(item.id)
      }
    }
    // Main Table Config End.

    const pollElements = ref([])

    const fetchPoll = id => {
      axios.get(`/fa/poll/${id}`)
        .then(rs => {
          pollData.value = rs.data
        })
        .catch(error => {
          toast({
            component: ToastificationContent,
            props: {
              title: errorFormatter(error, '데이터 조회에 실패하였습니다.'),
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }

    const fetchPolls = (ctx, callback) => {
      axios.post('/fa/poll/list', {
        search: {
          degreeMonth: query.value.degreeMonth,
          degree: query.value.degree,
          filterStr: query.value.filterStr,
          joinDateFr: query.value.joinDateFr ? `${query.value.joinDateFr}T00:00:00` : null,
          joinDateTo: query.value.joinDateTo ? `${query.value.joinDateTo}T23:59:59` : null,
        },
        sort: {
          predicate: sortBy.value,
          reverse: isSortDesc.value,
        },
      })
        .then(rs => {
          const { items, totalRecord } = rs.data
          callback(items)
          totalRecords.value = totalRecord
        })
        .catch(error => {
          toast({
            component: ToastificationContent,
            props: {
              title: errorFormatter(error, '데이터 조회에 실패하였습니다.'),
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }

    const savePoll = () => {
      const validPromise = refs.formRules.validate()
      validPromise.then(valid => {
        if (!valid) return

        const { id } = pollData.value
        if (id) {
          updatePoll()
        } else {
          saveNewPoll()
        }
      })
    }

    const saveNewPoll = () => {
      axios.put('/fa/poll/new', pollData.value)
        .then(rs => {
          toast({
            component: ToastificationContent,
            props: {
              title: '신규 Poll 등록 완료',
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          })
          if (rs.data.id) {
            pollData.value.id = rs.data.id
          }
          refetchData()
        })
        .catch(error => {
          toast({
            component: ToastificationContent,
            props: {
              title: errorFormatter(error, '데이터를 저장하는 과정에서 오류가 발생하였습니다.'),
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }

    const updatePoll = () => {
      axios.patch(`/fa/poll/update/${pollData.value.id}`, pollData.value)
        .then(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: '데이터 변경 완료',
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          })
          refetchData()
        })
        .catch(error => {
          toast({
            component: ToastificationContent,
            props: {
              title: errorFormatter(error, '데이터를 저장하는 과정에서 오류가 발생하였습니다.'),
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }

    const removePoll = () => {
      if (!pollData.value.id) {
        toast({
          component: ToastificationContent,
          props: {
            title: '삭제할 대상을 선택하세요.',
            icon: 'AlertTriangleIcon',
            variant: 'warning',
          },
        })
        return
      }
      bvModal
        .msgBoxConfirm('삭제 하시겠습니까?', {
          size: 'sm',
          cancelVariant: 'outline-secondary',
          centered: true,
        })
        .then(confirm => {
          if (!confirm) return

          axios.delete(`/fa/poll/${pollData.value.id}`)
            .then(() => {
              toast({
                component: ToastificationContent,
                props: {
                  title: '데이터 삭제 완료',
                  icon: 'CheckCircleIcon',
                  variant: 'success',
                },
              })
              refetchData()
              resetPollData()
            })
            .catch(error => {
              toast({
                component: ToastificationContent,
                props: {
                  title: errorFormatter(error, '삭제하는 과정에서 오류가 발생하였습니다.'),
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
            })
        })
    }

    const addElement = () => {
      if (!pollData.value.id) {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Poll 생성(또는 선택)후, 문항을 추가하세요.',
            icon: 'AlertTriangleIcon',
            variant: 'warning',
          },
        })
        return
      }

      pollElements.value.push({
        id: null,
        pollId: pollData.value.id,
        title: '',
        pollElementDetails: [],
      })
    }

    const addElementDetail = element => {
      element.pollElementDetails.push({
        pollElementId: element.id,
        subText: '',
      })
    }

    const fetchPollElements = id => {
      axios.get(`/fa/poll/elements/${id}/`)
        .then(rs => {
          pollElements.value = rs.data
        })
        .catch(() => {
          pollElements.value = []
        })
    }

    const removeElement = item => {
      bvModal
        .msgBoxConfirm('해당 문항을 삭제 하시겠습니까?', {
          size: 'sm',
          cancelVariant: 'outline-secondary',
          centered: true,
        })
        .then(confirm => {
          if (!confirm) return

          const idx = pollElements.value.indexOf(item)
          if (idx !== -1) {
            pollElements.value.splice(idx, 1)
          }

          bvModal.msgBoxOk('문항 추가, 삭제, 변경은 [저장]버튼을 눌러야 최종 반영됩니다.')
        })
    }

    const removeElementDetail = (elementDetails, dIdx) => {
      elementDetails.splice(dIdx, 1)
    }

    const savePollElements = () => {
      if (!pollData.value.id) {
        toast({
          component: ToastificationContent,
          props: {
            title: '문항 업데이트는 Poll 생성(또는 선택)후에 진행하세요.',
            icon: 'AlertTriangleIcon',
            variant: 'warning',
          },
        })
        return
      }

      const elements = pollElements.value
      if (elements.length === 0) {
        toast({
          component: ToastificationContent,
          props: {
            title: '최소 1개 문항은 등록되어야 합니다.',
            icon: 'AlertTriangleIcon',
            variant: 'warning',
          },
        })
        return
      }

      let anyDetails = true
      elements.every(el => {
        if (el.pollElementDetails.length === 0) {
          anyDetails = false
          return false
        }
        return true
      })
      if (!anyDetails) {
        toast({
          component: ToastificationContent,
          props: {
            title: '문항 "보기"는 최소 1개 이상 등록되어야 합니다.',
            icon: 'AlertTriangleIcon',
            variant: 'warning',
          },
        })
        return
      }

      const validPromise = refs.elementRules.validate()
      validPromise.then(valid => {
        if (!valid) return

        axios.patch(`/fa/poll/update-elements/${pollData.value.id}`, elements)
          .then(() => {
            toast({
              component: ToastificationContent,
              props: {
                title: '문항 업데이트 완료',
                icon: 'CheckCircleIcon',
                variant: 'success',
              },
            })
            refetchData()
          })
          .catch(error => {
            toast({
              component: ToastificationContent,
              props: {
                title: errorFormatter(error, '데이터를 저장하는 과정에서 오류가 발생하였습니다.'),
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          })
      })
    }

    return {
      query,
      codes,
      pollData,
      tableColumns,
      refPollTable,
      sortBy,
      isSortDesc,
      required,
      refetchData,
      totalRecords,
      fetchPolls,
      resetPollData,
      onRowSelectedPoll,
      savePoll,
      removePoll,
      pollElements,
      addElement,
      addElementDetail,
      removeElement,
      removeElementDetail,
      savePollElements,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
